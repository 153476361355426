<template>
  <FocusTrap>
    <div class="card" v-on:keydown="movefocus($event)">

    <div class="card-header header-elements-sm-inline py-sm-0" style="background-color: lightgrey;padding: 0px!important;" >

      <h5 class="card-title py-sm-3" ><span class="icon-list"></span>&nbsp;&nbsp;Ledger Group List</h5>

      <div class="header-elements" >
        <form action="#">
          <div class="form-group-feedback form-group-feedback-right">
            <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
            <div class="form-control-feedback">
              <i class="icon-search4 font-size-base text-muted"></i>
            </div>
          </div>
        </form>

      </div>
    </div>

    <div class="card-body" style="padding: 0px;">
      <div class="table-responsive">
      <table id="LedgerGroupForm-table"
             class="table table-bordered table-condensed table-columned"
             data-search="false"
             data-pagination="true"
             stickyHeader="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-width="100" data-field="id" data-sortable="true" >ID</th>
          <th data-field="name" data-sortable="true">Name</th>
          <th data-field="parent_name" data-sortable="true">Parent</th>
          <th data-field="nature" data-width="200" data-sortable="true" data-formatter="formatNature">Nature</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>
    </div>
    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" > <i class="icon-blocked text-danger" ></i>Delete</a>
      </div>
    </div>
    <!-- End of Context Menu -->


    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

    <vmodal name="ledgergroup_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="75%" :height="470" @before-open="beforeOpen" @before-close="beforeClose" >
      <LedgerGroupForm v-bind:mygroup="group" v-on:ledgergroup_saved="loadData" v-on:ledgergroup_window_closed="closeModal" ></LedgerGroupForm>
    </vmodal>

  </div>
  </FocusTrap>
</template>

<script>

import {userService} from '@/store/auth-header.js';
import LedgerGroupForm from './LedgerGroupForm'

export default {
  name: 'LedgerGroupFormView',
  components: {
    LedgerGroupForm
  },
  data () {
    return {
      mytable: {},
      group: {}
    }
  },
  created () {
    // Toggle min sidebar class
    $('.sidebar-main-toggle').on('click', function (e) {
      e.preventDefault();

      $('body').toggleClass('sidebar-xs').removeClass('sidebar-mobile-main');
      revertBottomMenus();
    });

  },
  mounted () {
    let self = this;

    this.$data.mytable = $('#LedgerGroupForm-table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    $('#LedgerGroupForm-table').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyDocument(id);
        //}else if ($(e.target).text() === 'Refresh') {
        //  self.removeDocument(id);
        }else if ($(e.target).text() === 'Delete') {
          self.removeDocument(id);
        }
      }
    });

    $('#txtsearch').keyup(function () {

      var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#LedgerGroupForm-table>tbody>tr').show().filter(function () {
        var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
        return !~text.indexOf(val)
      }).hide();

    });

    self.loadData();
  },
  methods: {
    movefocus (evt) {
      const self = this;

      let keycode = (evt.keyCode ? evt.keyCode : evt.which)

      if (keycode == '13' || keycode == '40') {

        evt.preventDefault(true);
        evt.stopPropagation(true);
        evt.stopped = true;

        nextInput();
      }else if (keycode == '38') {
        evt.preventDefault(true);
        evt.stopPropagation(true);
        evt.stopped = true;
        priviousInput();

      } else if (keycode == '120') {
        evt.preventDefault(true)
        evt.stopPropagation(true)
        evt.stopped = true;

        self.perform_clear_action();

      } else if (keycode == '121') {
        evt.preventDefault(true)
        evt.stopPropagation(true)
        evt.stopped = true

        self.perform_save_action();

      }
    },
    closeModal () {
      this.$modal.hide('ledgergroup_window')
    },
    beforeOpen(){

    },
    beforeClose(){

    },
    showModal () {
      this.group= JSON.parse('{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_id":0,"nature":0,"is_profit":false,"is_subledger":false,"is_net_bal":false,"is_calc":false}');
      this.$modal.show('ledgergroup_window');
    },

    modifyDocument (id) {
      let self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.group = {};
      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/ledgergroup/${id}`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.ok) {
          self.$data.group = data.data;
          self.$modal.show('ledgergroup_window');
        } else {
          swal ( { title: "Oops!" ,  text: data.message, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });
    },
    removeDocument (id) {
      let self = this;

      self.$data.group.id = id;

      const requestOptions = {
        method: 'DELETE',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.group)
      };

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete tms!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/ledgergroup`,requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('ledgergroup_window')
            self.loadData();
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            );
            // swal ( { title: "Success!" ,  text: data.message, type:  "success"} );
          }).catch(function (err) {
            swal({ title: "Oops", text: err.toString(), type: "error" });
          });
        }
      });

    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      this.$modal.hide('ledgergroup_window');

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });


      self.$data.mytable.bootstrapTable('load',[]);


      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/ledgergroups/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.mytable.bootstrapTable('load', resp.data);
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      });

    }
  }

}
</script>

<style scoped>

</style>
